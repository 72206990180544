import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  p: {
    color: theme.palette.text.primary,
  },
}));

interface MuiButtonProps {
  color?: 'primary' | 'secondary' | undefined;
  disabled?: boolean;
}

interface Props {
  variant?: 'text' | 'outlined' | 'contained';
}

const ButtonGroup = ({ variant }: Props) => {
  const classes = useStyles();
  const propsArray: MuiButtonProps[] = [
    {},
    { color: 'primary' },
    { color: 'secondary' },
    { disabled: true },
  ];
  return (
    <>
      {propsArray.map((props, index) => (
        <Button
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={classes.button}
          variant={variant}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        >
          Text
        </Button>
      ))}
    </>
  );
};

ButtonGroup.defaultProps = {
  variant: 'contained',
};

export default ButtonGroup;
