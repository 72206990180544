import SagasCounter from 'components/SagasCounter';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { State } from 'redux-app/types';

type ActionType =
  | 'INCREMENT'
  | 'DECREMENT'
  | 'INCREMENT_IF_ODD'
  | 'INCREMENT_ASYNC';

interface Props {
  action: (type: ActionType) => void;
  counter: number;
}

const SagasCounterExample = ({ action, counter }: Props) => (
  <>
    <h2>redux-saga counter example</h2>
    <Link to="/">Home</Link>
    <SagasCounter
      value={counter}
      onIncrement={() => action('INCREMENT')}
      onDecrement={() => action('DECREMENT')}
      onIncrementIfOdd={() => action('INCREMENT_IF_ODD')}
      onIncrementAsync={() => action('INCREMENT_ASYNC')}
    />
  </>
);

const mapStateToProps = (state: State) => {
  const { counter } = state;
  return { counter };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
  const action = (type: ActionType) => dispatch({ type });
  return { action };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SagasCounterExample);
