import React from 'react';
import { Link, Route, Switch, RouteComponentProps } from 'react-router-dom';

interface MatchParams {
  topicId?: string;
}

const Home = () => (
  <div>
    <h2>Home</h2>
  </div>
);

const About = () => (
  <div>
    <h2>About</h2>
  </div>
);

const Topic = ({ match }: RouteComponentProps<MatchParams>) => (
  <div>
    <h3>{match.params.topicId}</h3>
  </div>
);

const Topics = ({ match }: RouteComponentProps) => (
  <div>
    <h2>Topics</h2>
    <p>
      <Link to={`${match.url}/rendering`}>Rendering with React</Link>
    </p>
    <p>
      <Link to={`${match.url}/components`}>Components</Link>
    </p>
    <p>
      <Link to={`${match.url}/props-v-state`}>Props v. State</Link>
    </p>

    <Route path={`${match.url}/:topicId`} component={Topic} />
    <Route
      exact
      path={match.url}
      render={() => <h3>Please select a topic.</h3>}
    />
  </div>
);

const RouterExample = ({ match }: RouteComponentProps) => (
  <main>
    <p>This is an example page based on react-router 5</p>
    <p>
      <Link to="/">Home</Link>
    </p>
    <p>
      <Link to={`${match.url}/about`}>About</Link>
    </p>
    <p>
      <Link to={`${match.url}/topics`}>Topics</Link>
    </p>

    <Switch>
      <Route exact path={match.path} component={Home} />
      <Route path={`${match.path}/about`} component={About} />
      <Route path={`${match.path}/topics`} component={Topics} />
    </Switch>
  </main>
);

export default RouterExample;
