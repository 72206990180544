import React from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ButtonGroup from './components/ButtonGroup';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  p: {
    color: theme.palette.text.primary,
  },
}));

const MaterialUIDemo = () => {
  const classes = useStyles();
  return (
    <Paper style={{ padding: 100 }}>
      <Typography variant="h4" gutterBottom>
        Material-UI Demo
      </Typography>
      <Link to="/">Home</Link>
      <p className={classes.p}>Contained</p>
      <ButtonGroup variant="contained" />

      <p className={classes.p}>Outlined</p>
      <ButtonGroup variant="outlined" />

      <p className={classes.p}>Text</p>
      <ButtonGroup />
    </Paper>
  );
};

export default MaterialUIDemo;
