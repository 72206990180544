import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import './App.css';
import MaterialUIDemo from './containers/MaterialUIDemo';
import RouterExample from './containers/RouterExample';
import SagasCounterExample from './containers/SagasCounterExample';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Switch>
          <Route path="/" exact>
            <img
              src={
                process.env.REACT_APP_LOGO_URL ||
                'https://ucarecdn.com/73117109-0708-411b-89b2-9d74f0911d10/-/resize/200x/'
              }
              className="SSG-logo"
              alt="SSG logo"
            />
            <p>
              Edit <code>src/App.js</code> and save to reload.
            </p>
            <a
              className="App-link"
              href="https://reactjs.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn React
            </a>
            <br />
            Examples:
            <Link className="App-link" to="/redux-sagas-example">
              redux-sagas
            </Link>
            <Link className="App-link" to="/react-router-example">
              react-router
            </Link>
            <Link className="App-link" to="/material-ui">
              Material-UI
            </Link>
          </Route>
          <Route path="/react-router-example" component={RouterExample} />
          <Route path="/redux-sagas-example" component={SagasCounterExample} />
          <Route path="/material-ui" component={MaterialUIDemo} />
        </Switch>
      </header>
    </div>
  );
}
export default App;
